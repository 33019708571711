.Projects {
  flex: 1;
  min-height: 0;
  /* width:50vw; */
  margin: 1rem;

  font-size: 18pt;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-height: calc(100vh - 12rem);
  margin-bottom: 40px;
}

.Projects h1 {
  margin-bottom: 0.5rem;
}
.clickableLink {
  color: #239fc7;
  display: inline-block;
  width: -moz-fit-content;

  width: fit-content;
}
.clickableLink:hover {
  text-decoration: underline;
  cursor: pointer;
}

.wrappingColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  gap: 0.75rem;
}

.wrappingColumnContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 40px;
}



$height: 16px;
$halfheight: ($height/2);
$thickness: 130%; // increase to make the line thicker
$offset: 4px;
$backgroundcolor: #F0E8D1;
$linecolor: black;

.Subheader{
display:flex;
flex-direction:column;
// margin: 1rem 0;
// border-bottom: 1px solid black;
}
.Subheader h3{
margin:0;
}


.zigzag {
	margin: 7.5% 0;
	background: $backgroundcolor;
	position: relative;
	height: $height;
	z-index: 1;
	&:before,
	&:after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
  		right: 0;
	}
	&:before {
		height: ($height - $offset);
		top: $thickness;
		background: linear-gradient(-135deg, $linecolor $halfheight, transparent 0) 0 $halfheight, linear-gradient( 135deg, $linecolor $halfheight, transparent 0) 0 $halfheight;
		background-position: top left;
		background-repeat: repeat-x;
		background-size: $height $height;
	}
	&:after {
		height: $height;
		top: 100%;
		background: linear-gradient(-135deg, $backgroundcolor $halfheight, transparent 0) 0 $halfheight, linear-gradient( 135deg, $backgroundcolor $halfheight, transparent 0) 0 $halfheight;
		background-position: top left;
		background-repeat: repeat-x;
		background-size: $height $height;
	}
}
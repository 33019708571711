.About{
    flex:1;
    display:flex;
    flex-direction:column;
    min-height: 0;
    width:40vw;
    margin:2rem;
    font-size:18pt;
    min-height: calc(100vh - 8rem);
    padding-top:2rem;
}

.bioHead{
    border-bottom:1px solid zigzag;
}

@media (max-width: 1000px){
    .About{
        margin:.5rem;
        width:75vw;
    }
  
}
.Modal {
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 2rem;
  height: calc(100% - 4rem);

  z-index: 10;
  border-radius: 10px;
  background-color: #f5edee;
  display: flex;
  flex-direction: column;
  animation: slideUp 0.3s ease-out;

  @media (max-width: 600px) {
    margin: 0px;
    border-radius: 0px;
    height: 100vh;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

.modalHeader {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;

  border-radius: 7px 7px 0 0;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  background-color: #eac1d6;
  font-size: 22pt;
  font-weight: 700;
}

.close {
  cursor: pointer;
  width: 34px;
  height: 34px;
}

.modalContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding: 1rem;
  overflow: auto;
  gap: 1rem;
  border: 1px solid black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.modalContainer img {
  border-radius: 10px;
  border: 1px solid black;
  width: 100%;
  max-width: 700px;
}

.modalContainer h1 {
  margin-bottom: 1rem;
}
